export type CloudinaryImage = {
	public_id: string
	version: number
	url: string
	secure_url: string
	width: number
	height: number
	format: string
	resource_type: string
	bytes: number
	tags: string[]
	context: {
		custom: {
			alt: string
			caption: string
		}
	}
}
type imgSizesParams = {
	image: CloudinaryImage
	range: number[]
	intent: string
	isSrc?: boolean
	aspect?: number
	height?: number
}

export function imgSizes({ image, range, intent, isSrc = false, aspect, height }: imgSizesParams) {
	if (!aspect) {
		if (intent === 'card') aspect = 1
	}
	const ar = aspect ? `ar_${(aspect | 1).toPrecision(2)},` : ''

	const format = intent === 'illustration' && image.format != 'svg' ? 'auto' : 'svg'
	const transforms = {
		hero: `dpr_auto/f_auto/q_auto/${ar}c_fill,g_faces`,
		card: `dpr_auto/f_auto/q_auto/${ar}c_fill,g_auto`,
		illustration: `dpr_auto/f_${format}/q_auto/${ar}c_lfill`,
		default: `dpr_auto/f_auto/q_auto/${ar}c_limit`
	}

	const srcset = []
	for (const size of range) {
		const roundedSize = Math.round(size)
		const imgHeight = height ? `,h_${height}` : ''
		if (isSrc) {
			srcset.push(`https://res.cloudinary.com/thomasedison/image/upload/${transforms[intent]},w_${roundedSize}${imgHeight}/${image.public_id}`)
		} else {
			srcset.push(`https://res.cloudinary.com/thomasedison/image/upload/${transforms[intent]},w_${roundedSize}${imgHeight}/${image.public_id} ${roundedSize}w`)
		}
	}
	return srcset.join(',')
}

export function picture({ image, range, intent, aspect }: imgSizesParams) {
	if (!aspect) {
		if (intent === 'card') aspect = 1
	}
	const ar = aspect ? `ar_${(aspect | 1).toPrecision(2)},` : ''
	const transforms = {
		hero: `dpr_auto/f_auto/q_auto/${ar}c_fill,g_auto`,
		card: `dpr_auto/f_auto/q_auto/${ar}c_fill,g_auto`,
		illustration: `dpr_auto/f_svg/q_auto/${ar}c_lfill`
	}

	const srcset = []
	for (const size of range) {
		srcset.push(`https://res.cloudinary.com/thomasedison/image/upload/${transforms[intent]},w_${Math.round(size)}/${image.public_id} ${roundedSize}w`)
	}
	return {
		src: `https://res.cloudinary.com/thomasedison/image/upload/${transforms[intent]},w_${Math.round(range[range.length - 1])}/${image.public_id}`,
		srcset: srcset.join(',')
	}
}
